.project-container {
	height: 80vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 80px;
}

.project-container h3 {
	font-size: 150px;
	font-weight: 700;
	text-align: center;
}

.project-container p {
	font-size: 32px;
	text-align: center;
	width: 50%;
}

.project-container button {
	font-size: 32px;
	position: relative;
	overflow: hidden;
	border: 3px solid var(--Black);
	border-radius: 10px;
	cursor: pointer;
	background-color: var(--P-Green);
	color: var(--Black);
}

.project-container button:hover {
	background-color: var(--White);
}

.btn-pri {
	display: block;
	width: 100%;
	height: 100%;
	padding: 10px 20px;
	background-color: var(--P-Green);
	position: relative;
	z-index: 2;
	transition: transform 0.4s cubic-bezier(0.79, -0.08, 0.37, 1.5);
	box-sizing: border-box;
}

.btn-sec {
	transform: translate3d(-100%, 0, 0);
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	width: 100%;
	height: 100%;
	padding: 10px 20px;
	background-color: var(--White);
	white-space: nowrap;
	transition: transform 0.4s cubic-bezier(0.79, -0.08, 0.37, 1.5);
	box-sizing: border-box;
}

.btn-shift .btn-pri {
	transform: translate3d(100%, 0, 0);
}

.btn-shift .btn-sec {
	transform: translate3d(0, 0, 0);
}

@media screen and (max-width: 768px) {
	.project-container h3 {
		font-size: 60px;
	}

	.project-container p {
		font-size: 24px;
		width: 80%;
	}
	.project-container button {
		font-size: 24px;
	}
}
