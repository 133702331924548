.skills {
	overflow: hidden;
	position: relative;
	background-color: var(--White);
}

.skills-container {
	background-color: var(--White);
	height: 81vh;
	width: 100vw;
	font-size: 7vw;
	animation: slideOne 10s infinite ease-in-out;
	will-change: transform;
}

.skills-block {
	height: 100%;
	width: 100%;
}

@keyframes slideOne {
	0% {
		transform: translateX(-90%);
	}
	50% {
		transform: translateX(5%);
	}
	100% {
		transform: translateX(-90%);
	}
}

.section-one {
	display: flex;
	align-items: center;
	justify-content: start;
	white-space: nowrap;
	height: 27vh;
}

.section-one h2 {
	font-family: "Manrope-Black", sans-serif;
	margin-right: 4vw;
}

.section-one h3 {
	margin-right: 4vw;
}

.section-one h4 {
	font-family: "Manrope-Light", sans-serif;
	margin-right: 4vw;
}

.section-one h5 {
	color: var(--P-Green);
	margin-right: 4vw;
}

.section-two {
	display: flex;
	align-items: center;
	justify-content: start;
	white-space: nowrap;
	height: 27vh;
}

.section-two h2 {
	margin-right: 4vw;
	color: var(--P-Green);
}

.section-two h3 {
	margin-right: 4vw;
	font-family: "Manrope-Black", sans-serif;
}

.section-two h4 {
	margin-right: 4vw;
}

.section-two h5 {
	margin-right: 4vw;
	font-family: "Manrope-Light", sans-serif;
}

.section-three {
	display: flex;
	align-items: center;
	justify-content: start;
	white-space: nowrap;
	height: 27vh;
}

.section-three h2 {
	margin-right: 4vw;
	font-family: "Manrope-Light", sans-serif;
}

.section-three h3 {
	margin-right: 4vw;
}

.section-three h4 {
	margin-right: 4vw;
	color: var(--P-Green);
}

.section-three h5 {
	margin-right: 4vw;
	font-family: "Manrope-Black", sans-serif;
}

.skills-controller {
	display: flex;
	justify-content: center;
	align-items: center;
}

.controller-button {
	text-align: center;
	width: 50px;
	height: 50px;
	padding-bottom: 30px;
	z-index: 3;
	color: var(--P-Green);
	cursor: pointer;
	animation: pulseIn 0.5s ease-in-out;
}

.pause {
	animation-play-state: paused !important;
}

@keyframes pulseIn {
	0% {
		opacity: 0;
		transform: scale(1);
	}
	50% {
		transform: scale(1.1);
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}

/*MEDIA BREAKPOINTS
*********************************************************************************************/

@media screen and (max-width: 600px) {
	.skills-container {
		font-size: 12vw;
		animation: slideOne 8s infinite ease-in-out;
	}
	@keyframes slideOne {
		0% {
			transform: translateX(-215%);
		}
		50% {
			transform: translateX(5%);
		}
		100% {
			transform: translateX(-215%);
		}
	}
	.controller-button {
		width: 40px;
		height: 40px;
		margin-top: -50px;
		padding-bottom: 30px;
	}
}
