.scroll-up-button {
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 10;
	width: 70px;
	height: 70px;
	border: none;
	cursor: pointer;
	background-color: transparent;
	transition: background-color 0.3s ease-in-out;
}

.scroll-up-icon {
	transform: translate3d(0, 0, 0); /* Forces GPU rendering for dumb safari */
	width: 70px;
	height: 70px;
	color: var(--S-Green);
	transition: all 0.3s ease-in-out;
}

.scroll-up-icon:hover {
	color: var(--P-Green);
}

/*MEDIA BREAKPOINTS
*********************************************************************************************/

@media screen and (max-width: 900px) {
	.scroll-up-button {
		height: 4.5rem;
		width: 4.5rem;
	}
	.scroll-up-icon {
		height: 4.5rem;
		width: 4.5rem;
	}
	.scroll-up-icon:hover {
		color: var(--S-Green);
	}
	.scroll-up-icon:active {
		color: var(--P-Green);
	}
}

@media screen and (max-width: 600px) {
	.scroll-up-button {
		right: 10px;
		bottom: 10px;
		height: 3.5rem;
		width: 3.5rem;
	}
	.scroll-up-icon {
		height: 3.5rem;
		width: 3.5rem;
	}
}
