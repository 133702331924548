.menu-button-container {
	position: fixed;
	left: 20px;
	top: 20px;
	display: flex;
	height: 70px;
	width: 70px;
	cursor: pointer;
	align-items: center;
	justify-content: center;
	z-index: 30;
	overflow: visible;
	border-radius: 50%;
	border: 3px solid var(--P-Green);
	transition: all 0.5s ease-in-out;
	animation: fade-in 0.5s ease-in-out;
}

.menu-button-container:hover {
	background-color: var(--S-Green);
}

.menu-spacer {
	overflow: visible;
}

.line1 {
	display: block;
	height: 7px;
	width: 62px;
	transform-origin: center;
	border-radius: 9999px;
	background-color: var(--P-Green);
	transition: transform 0.3s ease-in-out;
}

.line1-trans {
	transform: translateY(0.45rem) rotate(45deg);
}

.line2 {
	display: block;
	height: 7px;
	width: 62px !important;
	transform-origin: center;
	border-radius: 9999px;
	background-color: var(--P-Green);
	transition: transform 0.3s ease-in-out;
	margin-top: 0.45rem;
}

.line2-trans {
	transform: translateY(-0.45rem) rotate(-45deg);
	width: 5rem;
}

/*
*********************************************************************************************/

.menu-container {
	width: 25vw;
	height: 100vh;
	background-color: var(--Black);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 20;
	border-bottom-right-radius: 5rem;
	border-top-right-radius: 5rem;
	border-right: 5px solid var(--White);
	box-shadow: 0px 8px 12px var(--Black);
	transition: all 0.5s ease-in-out;
}

.menu-open {
	transform: translateX(0%);
}

.menu-closed {
	transform: translateX(-101%);
}

.menu-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.8);
	z-index: 10;
	transition: all 0.5s ease-in-out;
}

.menu-overlay-open {
	opacity: 1;
	pointer-events: all;
}

.menu-overlay-closed {
	opacity: 0;
	pointer-events: none;
}

.menu-wrapper {
	display: flex;
	flex-direction: column;
	text-align: right;
	justify-content: center;
	height: 100%;
	color: var(--P-Green);
	font-family: "Manrope-SemiBold", sans-serif;
	font-size: 3.5vw;
}

.menu-item-1 {
	animation: menu-slide-in 0.5s cubic-bezier(0.8, -0.05, 0.4, 1.2);
}

.menu-item-2 {
	animation: menu-slide-in 0.7s cubic-bezier(0.8, -0.05, 0.4, 1.2);
}

.menu-item-3 {
	animation: menu-slide-in 0.9s cubic-bezier(0.8, -0.05, 0.4, 1.2);
}

.menu-item-4 {
	animation: menu-slide-in 1.1s cubic-bezier(0.8, -0.05, 0.4, 1.2);
}

.menu-item-5 {
	animation: menu-slide-in 1.3s cubic-bezier(0.8, -0.05, 0.4, 1.2);
}

.menu-item-6 {
	animation: menu-slide-in 1.5s cubic-bezier(0.8, -0.05, 0.4, 1.2);
}

@keyframes menu-slide-in {
	0% {
		transform: translateX(-50%);
		opacity: 0;
	}
	100% {
		transform: translateX(0%);
		opacity: 1;
	}
}

.item h3 {
	cursor: pointer;
	padding-right: 2vw;
}

.item {
	transition: all 0.4s ease-in-out;
}

.item:hover {
	color: var(--White);
	background-color: var(--S-Green);
}

/*MEDIA BREAKPOINTS
*********************************************************************************************/

@media screen and (max-width: 900px) {
	.menu-button-container {
		height: 4.5rem;
		width: 4.5rem;
	}
	.line1 {
		width: 4rem;
		height: 0.4rem;
	}
	.line2 {
		width: 4rem;
		height: 0.4rem;
	}
	.menu-container {
		width: 30vw;
		border-bottom-right-radius: 2.5rem;
		border-top-right-radius: 2.5rem;
	}
	.menu-wrapper {
		font-size: 4vw;
	}
}

@media screen and (max-width: 600px) {
	.menu-button-container {
		left: 10px;
		top: 10px;
		height: 3.5rem;
		width: 3.5rem;
	}
	.menu-button-container:hover {
		background-color: transparent;
	}
	.menu-button-container:active {
		background-color: var(--S-Green);
	}
	.line1 {
		width: 3rem;
		height: 0.3rem;
	}
	.line2 {
		width: 3rem !important;
		height: 0.3rem;
		margin-top: 0.5rem;
	}
	.menu-container {
		width: 96vw;
		border-bottom-right-radius: 3rem;
		border-top-right-radius: 3rem;
	}
	.menu-wrapper {
		font-size: 13vw;
		gap: 2%;
	}
	.item h3 {
		padding-left: 10vw;
		text-align: left;
	}
}
