.about-container {
	position: relative;
	background-color: var(--Black);
	height: 100vh;
	border-bottom-left-radius: 5rem;
	border-bottom-right-radius: 4rem;
}

.about-container h3 {
	padding-top: 30px;
	text-align: center;
	color: var(--White);
	font-size: 100px;
	height: 15vh;
}

.about-body-wrapper {
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	justify-items: center;
	height: 80vh;
}

.about-body-text {
	text-align: left;
	color: var(--White);
	font-size: 1.5vw;
	margin: 20px;
}

.about-img {
	width: 80%;
	border: 5px solid var(--P-Green);
	border-radius: 50%;
	height: auto;
	box-sizing: border-box;
}

/*MEDIA BREAKPOINTS
*********************************************************************************************/

@media screen and (max-width: 1300px) {
	.about-body-text {
		font-size: 16px;
	}
}

@media screen and (max-width: 768px) {
	.about-container h3 {
		font-size: 60px;
	}
	.about-body-text {
		font-size: 16px;
		background-color: var(--Black);
		z-index: 4;
	}
	.about-body-wrapper {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 50% 50%;
	}
	.about-img {
		height: 100%;
		width: auto !important;
	}
}

@media screen and (max-width: 600px) {
	.about-container {
		border-bottom-left-radius: 3rem;
		border-bottom-right-radius: 3rem;
		height: 110vh;
	}
	.about-container h3 {
		padding-top: 4rem;
	}
	.about-img {
		height: auto;
		width: 90% !important;
	}
}
