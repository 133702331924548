.description-container {
	background-color: var(--Black);
	color: var(--White);
	font-family: "Manrope-Light", sans-serif;
	overflow-x: hidden;
	border: 1px;
	border-radius: 5rem;
	height: 100vh;
	position: relative;
}

.description-container p {
	font-size: 1.5vw;
}

.triangle-container {
	overflow: hidden;
	position: absolute;
	height: 50vh;
	width: 100%;
	top: 25vh;
}

.triangle-desc {
	display: flex;
	flex-direction: row;
	align-items: center;
	position: relative;
	justify-content: flex-end;
	background-color: var(--White);
	color: var(--Black);
	height: 50vh;
	width: 100%;
	clip-path: polygon(0% 50%, 100% 0%, 100% 100%);
	overflow: hidden;
}

.triangle-body-wrapper {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.triangle-body-wrapper p {
	width: 45vw;
	padding-right: 5vw;
	overflow: hidden;
}

.antenna svg {
	overflow: hidden;
	position: absolute;
	color: var(--P-Green);
	right: 20vw;
}

.description-body-start {
	height: 50%;
	padding-left: 7vw;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.description-body-start-para {
	width: 45vw;
}

.description-body-end {
	padding-left: 7vw;
	height: 50%;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.description-body-end-para {
	width: 45vw;
}

.desc-svg1 {
	color: #04c748;
	width: 5vw;
	height: 5vw;
	margin-left: 4vw;
}

.desc-svg2 {
	color: #04c748;
	width: 5vw;
	height: 5vw;
	margin-right: 4vw;
}

.hidden {
	visibility: hidden;
}

.from-right {
	animation: slideLeft 1.2s ease-in-out;
	visibility: visible;
}

.from-right-delay {
	animation: reboundBody 1.8s cubic-bezier(0.79, -0.08, 0.37, 1.47);
	visibility: visible;
}

@keyframes reboundBody {
	0% {
		transform: translateX(100%);
		opacity: 0;
	}

	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes slideLeft {
	0% {
		transform: translateX(100%);
		opacity: 0;
	}

	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

/*MEDIA BREAKPOINTS
*********************************************************************************************/

@media screen and (max-width: 768px) {
	.description-body-start-para {
		width: 50vw;
	}
	.description-body-end-para {
		width: 50vw;
	}
	.triangle-desc-para {
		width: 50vw;
	}
}

@media screen and (max-width: 600px) {
	.description-container {
		border-radius: 3rem;
	}
	.description-container p {
		font-size: 12px;
	}
	.triangle-desc p {
		padding-right: 1vw;
	}
	.description-body-end {
		padding-left: 4vw;
	}
	.description-body-start {
		padding-left: 4vw;
	}
	.desc-svg1 {
		width: 10vw;
		height: 10vw;
		margin-left: 4vw;
	}
	.desc-svg2 {
		width: 10vw;
		height: 10vw;
		margin-right: 4vw;
	}
}
