.footer-container {
	position: relative;
	background-color: var(--Black);
	height: 80vh;
	border: 1px;
	border-top-left-radius: 5rem;
	border-top-right-radius: 5rem;
}

.footer-container h3 {
	text-align: center;
	color: var(--White);
	font-size: 4vw;
}

.footer-experience {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
	grid-template-rows: 1fr 1fr;
	color: var(--P-Green);
	align-items: center;
	justify-items: center;
	font-size: 0.8vw;
	padding-top: 40px;
	margin-left: 20px;
	margin-right: 20px;
}

.footer-experience svg {
	height: 4vw;
	width: 4vw;
}

.footer-body-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 2vh;
	color: var(--White);
}

.footer-body-wrapper p {
	font-size: 1.6vw;
}

.footer-contact {
	color: var(--White);
}

.contact-link {
	color: var(--White);
	text-decoration: none;
	border-bottom: 1px solid var(--P-Green);
	transition: all 0.3s ease-in-out;
	font-size: 2.3vw;
}

.contact-link:hover {
	color: var(--P-Green);
}

.socials-icon-wrapper {
	width: 25%;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.linkedin-link {
	color: var(--White);
	text-decoration: none;
	border-bottom: 1px solid var(--P-Green);
	transition: all 0.3s ease-in-out;
	font-size: 2.3vw;
}

.linkedin-link:hover {
	color: var(--P-Green);
}

.footer-bottom {
	margin-top: 1vh;
	font-size: 1.2vw;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	color: var(--White);
}

.footer-bottom p,
.footer-bottom h4 {
	margin-right: 2vw;
	margin-left: 2vw;
	transition: all 0.3s ease-in-out;
}

.footer-legal:hover {
	color: var(--P-Green);
	cursor: pointer;
}

.resume {
	color: var(--White);
	text-decoration: none;
	font-size: 18px;
	padding: 10px 20px;
	background-color: var(--Black);
	color: var(--White);
	border: 1px solid var(--P-Green);
	border-radius: 10px;
	cursor: pointer;
	outline: none;
	transition: all 0.3s ease-in-out;
}

.resume:hover {
	background-color: var(--P-Green);
	color: var(--Black) !important;
}

/*MEDIA BREAKPOINTS
*********************************************************************************************/

@media screen and (max-width: 900px) {
	.footer-container h3 {
		font-size: 8vw;
	}
	.footer-body-wrapper p {
		font-size: 3vw;
	}
	.footer-bottom {
		font-size: 2.5vw;
	}
	.socials-icon-wrapper {
		width: 50%;
	}
	.contact-link {
		font-size: 5vw;
	}
	.linkedin-link {
		font-size: 5vw;
	}
}

@media screen and (max-width: 768px) {
	.footer-experience {
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
		grid-template-rows: 1fr 1fr;
		gap: vw;
	}
	.footer-experience p {
		display: none;
	}
	.footer-experience svg {
		margin-top: 3vw;
		height: 10vw;
		width: 10vw;
	}
	.footer-container h3 {
		margin-top: 5vw;
	}
}

@media screen and (max-width: 600px) {
	.footer-container {
		height: 80vh;
		border-top-left-radius: 3rem;
		border-top-right-radius: 3rem;
	}
	.footer-container h3 {
		font-size: 9vw;
	}
	.footer-body-wrapper p {
		font-size: 3.5vw;
	}
	.footer-bottom {
		font-size: 3.2vw;
	}
	.socials-icon-wrapper {
		width: 60%;
	}
	.contact-link {
		font-size: 6vw;
	}
	.linkedin-link {
		font-size: 6vw;
	}
	.resume {
		font-size: 14px;
	}
}
