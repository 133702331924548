.hero {
	height: 100vh;
	width: 100%;
	background-color: var(--Black);
	display: grid;
	grid-template-columns: 1fr 1fr;
	border-bottom: 2px solid var(--White);
}

.hero-content {
	color: var(--White);
	display: flex;
	text-align: center;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 40px;
}

.hero-title {
	font-size: 5rem;
}

.hero-title svg {
	width: 3.5rem;
	height: 3.5rem;
}

.hero-name {
	font-family: "Manrope-Black", sans-serif;
	color: var(--P-Green);
}

.hero-subtitle {
	font-size: 4rem;
}

.hero-img-wrapper {
	position: relative;
	border-radius: 9999px;
	overflow: hidden;
}

.hero-img {
	position: absolute;
	width: 98%;
	height: auto;
	object-fit: cover;
	top: 50%;
	left: 50%;
	border-radius: 9999px;
	transform: translate(-50%, -50%);
	z-index: 5;
}

.hero-img-shadow {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 9999px;
	box-shadow: inset 0 0 160px 160px var(--Black);
	z-index: 6;
}

@media screen and (max-width: 1080px) {
	.hero {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 60% 40%;
	}
	.hero-img-wrapper {
		order: 1;
	}
	.hero-content {
		order: 2;
	}
	.hero-img-shadow {
		box-shadow: inset 0 0 80px 80px var(--Black);
	}
}

@media screen and (max-width: 768px) {
	.hero-title {
		font-size: 3rem;
	}
	.hero-title svg {
		width: 2rem;
		height: 2rem;
	}
	.hero-subtitle {
		font-size: 2rem;
	}
	.hero-img {
		width: 100vw;
		height: auto;
	}
}
