/* Global styles
*********************************************************************************************/
* {
	margin: 0;
	padding: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

body {
	height: 100%;
	overflow-x: hidden;
	background-color: var(--White);
	scroll-behavior: smooth;
}

.app {
	position: relative;
}

:root {
	--Black: #0c0c0c;
	--White: #fff7ee;
	--P-Green: #04c748;
	--S-Green: #04c74835;
}

body::-webkit-scrollbar {
	width: 12px;
}

body::-webkit-scrollbar-track {
	background-color: #373737;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}

body::-webkit-scrollbar-thumb {
	background-color: #141414;
	border-radius: 1rem;
}

/* Font styles
*********************************************************************************************/
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
	--webkit-font-smoothing: antialiased;
}

@font-face {
	font-family: "Manrope-Light";
	src: url(./fonts/Manrope-Light.ttf) format("truetype");
}

@font-face {
	font-family: "Manrope-Regular";
	src: url(./fonts/Manrope-Regular.ttf) format("truetype");
}

@font-face {
	font-family: "Manrope-SemiBold";
	src: url(./fonts/Manrope-SemiBold.ttf) format("truetype");
}

@font-face {
	font-family: "Manrope-Black";
	src: url(./fonts/Manrope-ExtraBold.ttf) format("truetype");
}

/* Global  animations
*********************************************************************************************/

.fade-in {
	visibility: visible;
	animation: fade-in 1.5s ease-in-out;
}

@keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.in-view {
	visibility: visible;
	animation: fadeIntoView 1s ease-in-out;
}

@keyframes fadeIntoView {
	from {
		opacity: 0;
		transform: translateY(80px);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}

.in-view-from-left {
	visibility: visible;
	animation: fadeInFromLeft 1.5s cubic-bezier(0.79, -0.08, 0.37, 1.47);
}

@keyframes fadeInFromLeft {
	from {
		opacity: 0;
		transform: translateX(-100%);
	}
	to {
		opacity: 1;
		transform: translateX(0);
	}
}

.in-view-from-right {
	visibility: visible;
	animation: fadeInFromRight 1.5s cubic-bezier(0.79, -0.08, 0.37, 1.47);
}

@keyframes fadeInFromRight {
	from {
		opacity: 0;
		transform: translateX(100%);
	}
	to {
		opacity: 1;
		transform: translateX(0);
	}
}

.hidden {
	visibility: hidden;
}
