.legals-container {
	min-height: 100vh;
	height: 100%;
	width: 100%;
	background-color: var(--Black);
}

.back-button-legal {
	position: fixed;
	top: 2rem;
	left: 2rem;
	z-index: 3;
	width: 6.1rem;
	height: 6.1rem;
	border: none;
	cursor: pointer;
	background-color: transparent;
	border-radius: 50%;
	transition: all 0.3s ease-in-out;
	display: flex;
	align-items: center;
	justify-content: center;
	animation: fade-in 0.5s ease-in-out;
}

.back-button-icon-legal {
	width: 6rem;
	height: 6rem;
	transition: all 0.3s ease-in-out;
	color: var(--P-Green);
}

.back-button-legal:hover {
	background-color: var(--P-Green);
}

.back-button-icon-legal:hover {
	color: var(--Black);
}

.legals-wrapper {
	width: 80%;
	margin-left: 10%;
	padding-bottom: 10vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.legals-wrapper h1 {
	font-family: "Manrope-SemiBold", sans-serif;
	text-align: center;
	color: var(--P-Green);
	font-size: 60px;
	padding-top: 10vh;
	padding-bottom: 5vh;
}

.legals-wrapper h2 {
	font-family: "Manrope-SemiBold", sans-serif;
	text-align: left;
	color: var(--P-Green);
	font-size: 40px;
}

.legals-wrapper p {
	font-family: "Manrope-Regular", sans-serif;
	text-align: left;
	color: var(--White);
	font-size: 20px;
}

@media screen and (max-width: 900px) {
	.back-button-legal {
		width: 4.1rem;
		height: 4.1rem;
	}
	.back-button-icon-legal {
		width: 4rem;
		height: 4rem;
	}
}

@media screen and (max-width: 768px) {
	.back-button-legal {
		left: 1rem;
		top: 1rem;
		height: 3.5rem;
		width: 3.5rem;
	}
	.back-button-icon-legal {
		width: 3.5rem;
		height: 3.5rem;
	}
	.legals-wrapper {
		width: 90%;
		margin-left: 5%;
	}
	.legals-wrapper h1 {
		font-size: 24px;
		padding-bottom: 2vh;
	}
	.legals-wrapper h2 {
		font-size: 20px;
	}
	.legals-wrapper p {
		font-size: 14px;
	}
}
