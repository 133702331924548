.works-container {
	height: auto;
	min-height: 100vh;
	font-family: "Manrope-SemiBold", sans-serif;
	padding-bottom: 30vh;
	background-color: var(--Black);
	overflow-x: hidden;
}

.works-container h3 {
	text-align: center;
	color: var(--White);
	font-size: 100px;
	padding-bottom: 15px;
}

.works-wrapper {
	width: 100%;
	margin-top: 180px;
	margin-bottom: 180px;
}

.work-item-wrapper {
	position: relative;
	transition: all 0.3s ease-in-out;
	height: 6vh;
	overflow: hidden;
}

.work-item-expanded {
	height: 750px;
	transition: all 0.3s ease-in-out;
}

.work-item-title {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	align-items: center;
	width: 100%;
	margin-bottom: 5px;
	overflow: hidden;
	position: relative;
	z-index: 1;
	color: var(--White);
	transition: color 0.3s ease-in-out;
}

.work-item {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	align-items: center;
	border-top: 2px solid var(--P-Green);
	width: 100%;
	height: 6vh;
	overflow: hidden;
	cursor: pointer;
	position: relative;
	z-index: 1;
	color: var(--White);
	transform: translateX(0);
	transition: all 0.3s ease-in-out;
}

.work-item:hover {
	color: var(--P-Green);
	padding-left: 10px;
}

.work-item h4,
.work-item-title h4 {
	font-size: 28px;
}

.work-desc {
	font-size: 24px !important;
}

.work-img {
	position: absolute;
	width: 320px;
	height: auto;
	max-height: 720px;
	object-fit: cover;
	overflow: hidden;
	top: 50%;
	left: 18%;
	transform: translate(-50%, -50%);
	z-index: 5;
	pointer-events: none;
	border-radius: 16px;
	border: 2px solid var(--White);
	background-color: var(--Black);
	transition: all 0.3s ease-in-out;
}

.work-img-shown {
	opacity: 1;
}

.work-img-hidden {
	opacity: 0;
}

.work-item-content {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	align-items: center;
	height: 100%;
}

.work-item-description {
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 20px;
	width: 100%;
	color: var(--White);
	font-size: 18px;
}

.work-item-description button {
	font-size: 20px;
	font-family: "Manrope-SemiBold";
	width: 260px;
	cursor: pointer;
	position: relative;
	padding: 10px 20px;
	transition: all 0.3s ease-in-out;
	background-color: var(--White);
	color: var(--Black);
	border: 2px solid var(--White);
}

.work-item-description button:hover {
	background-color: var(--Black);
	color: var(--White);
}

.work-item-content img {
	width: 500px;
	height: 260px;
	object-fit: cover;
	margin: 0 auto;
	border-radius: 16px;
	border: 2px solid var(--White);
	background-color: var(--Black);
}

.ripple {
	position: absolute;
	border-radius: 50%;
	transform: scale(0);
	background-color: var(--Black);
	animation: ripple-effect 1.2s linear;
	pointer-events: none;
	z-index: 999;
}

.back-btn {
	position: absolute;
	top: 30px;
	left: 30px;
	font-size: 50px;
	color: var(--White);
	cursor: pointer;
	transition: all 0.3s ease-in-out;
}

.back-btn:hover {
	color: var(--P-Green);
}

@keyframes ripple-effect {
	to {
		transform: scale(5);
	}
}

/*MEDIA BREAKPOINTS
*********************************************************************************************/

@media screen and (max-width: 1450px) {
	.works-wrapper {
		margin-top: 100px;
		margin-bottom: 100px;
		margin: 50px 0 !important;
	}
	.work-item-title {
		grid-template-columns: 1fr 1fr;
		justify-content: center;
		padding: 0 20px;
	}
	.work-item {
		grid-template-columns: 1fr 1fr;
		justify-content: center;
		padding: 0 20px;
	}
	.work-item:hover {
		padding-left: 30px;
	}
	.work-item-content {
		grid-template-columns: 1fr 1fr;
		padding: 0 20px;
		height: 600px;
	}
	.work-item-expanded {
		height: 700px;
	}
	.work-item-title h4,
	.work-item h4 {
		font-size: 20px;
	}
	.work-desc {
		font-size: 20px !important;
	}
}

@media screen and (max-width: 1000px) {
	.work-item-content {
		grid-template-columns: 1fr;
	}
	.work-item-content img {
		width: 100%;
		height: auto;
		margin: 20px 0;
	}
	.work-item-expanded {
		height: 970px;
	}
}

@media screen and (max-width: 600px) {
	.works-container h3 {
		font-size: 60px;
		padding-top: 40px;
	}
	.work-item {
		display: flex;
		align-items: center;
		padding: 0;
	}
	.work-item:hover {
		padding-left: 0;
	}
	.work-item-title h4,
	.work-item h4,
	.work-desc {
		font-size: 20px !important;
	}
	.work-item-description {
		font-size: 15px !important;
	}
	.work-item-content button {
		font-size: 16px;
		width: 100%;
	}
	.work-item-content img {
		width: 100%;
		height: auto;
		margin: 20px 0;
	}
	.back-btn {
		font-size: 30px;
		top: 20px;
		left: 20px;
	}
}
