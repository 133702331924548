.srp-container {
	min-height: 100vh;
	height: 100%;
	width: 100vw;
	background-color: #d66b23;
	font-family: "Manrope-Regular", sans-serif;
	color: var(--Black);
	display: flex;
	align-items: center;
	flex-direction: column;
	position: relative;
}

.srp-body {
	width: 80%;
	display: flex;
	background-color: var(--White);
	flex-direction: column;
	text-align: left;
	padding: 3vw;
	padding-bottom: 100px;
}

.srp-body h1 {
	font-family: "Manrope-Black", sans-serif;
	text-align: center;
	font-size: 4vw;
	margin-bottom: 2.5vw;
}

.srp-body h2 {
	font-size: 1.5vw;
	margin-bottom: 1.5vw;
}

.srp-body p {
	font-size: 1.2vw;
	margin-bottom: 1.5vw;
}

.srp-line {
	width: 100%;
	height: 5px;
	background-color: var(--Black);
	margin-bottom: 1vw;
}

.srp-img-mobile {
	align-self: center;
	width: 40%;
	height: auto;
	margin: 1vw;
	border: 2px solid var(--Black);
	pointer-events: none;
}

.srp-img-desktop {
	align-self: center;
	width: 100%;
	height: auto;
	margin: 1vw;
	border: 2px solid var(--Black);
	pointer-events: none;
}

@media screen and (max-width: 900px) {
	.srp-body {
		width: 90%;
	}
	.srp-body h1 {
		font-size: 5.5vw;
	}
	.srp-body h2 {
		font-size: 16px;
	}
	.srp-body p {
		font-size: 12px;
	}
	.srp-img-mobile {
		width: 100%;
	}
	.srp-img-desktop {
		width: 100%;
	}
}
