.arca-container {
	background-color: var(--Black);
	min-height: 100vh;
	height: 100%;
	width: 100vw;
	font-family: "Manrope-Regular", sans-serif;
	color: var(--White);
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	position: relative;
}

.arca-container video {
	width: 40%;
	height: auto;
}

.arca-body {
	width: 80%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: left;
	margin-bottom: 100px;
}

.arca-body h2 {
	font-size: 1.75vw;
	padding: 1vw;
}

.arca-body p {
	font-size: 1.2vw;
	padding-left: 1vw;
}

.arca-body ul {
	font-size: 1.2vw;
	padding-left: 3vw;
}

.arca-body ol {
	font-size: 1.2vw;
	padding-left: 3vw;
}

.arca-body pre {
	font-size: 1.2vw;
	padding-left: 3vw;
	background-color: #464646;
	border-radius: 1rem;
	margin: 1vw;
	overflow-x: auto;
}

.arca-body code {
	color: #d4ef21;
	background-color: #464646;
	border-radius: 1rem;
	padding-left: 8px;
	padding-right: 8px;
	word-wrap: break-word;
}

@media screen and (max-width: 900px) {
	.arca-container video {
		width: 100%;
	}
	.arca-body h2 {
		font-size: 16px;
	}
	.arca-body p {
		font-size: 12px;
	}
	.arca-body ul {
		font-size: 12px;
	}
	.arca-body ol {
		font-size: 12px;
	}
	.arca-body pre {
		font-size: 12px;
	}
	.arca-body code {
		font-size: 12px;
	}
}
