.spinner-container {
	width: 100%;
	min-width: 100vw;
	height: 100%;
	min-height: 100vh;
	position: relative;
	background-color: #fff7ee;
	animation: fade-in 0.5s ease-in-out;
}

.spinner::before {
	content: "";
	box-sizing: border-box;
	position: absolute;
	top: 48%;
	left: 50%;
	width: 48px;
	height: 48px;
	margin-top: -24px;
	margin-left: -24px;
	border-radius: 50%;
	border-top: 4px solid #141414;
	border-right: 4px solid transparent;
	animation: spinnerRotate 600ms linear infinite;
	border-left: 4px solid #141414;
}

@keyframes spinnerRotate {
	to {
		transform: rotate(360deg);
	}
}
